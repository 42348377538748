import React from 'react';
import {graphql} from 'gatsby';
import tw, {styled} from 'twin.macro';

import Layout from '../components/Layout';
import {Container} from '../components/Grid';

import SEO from '../components/Seo';
import {useI18next} from 'gatsby-plugin-react-i18next';
import FAQ from '../components/Page/FAQ';
import PageHero from '../components/shared/hero';
import WorkWithUs from '../components/Page/home/WorkWithUs';
import BookingProcess from '../components/Page/home/BookingProcess';
import {Button} from '../components/Button';
import ImageItems from '../components/shared/ImageItems';
import ImageText from '../components/Page/cleaning-service/ImageText';
import BookNowController from '../components/BookNowController';
import Reviews from '../components/Page/home/Reviews/Reviews';

const StyledBlock = styled.div`
  padding: 35px;
  background-color: #f8f9f8;

  @media ${props => props.theme.screens.md} {
    margin-bottom: 100px;
  }
  @media ${props => props.theme.screens.lg} {
    padding: 100px;
    margin-bottom: 0px;
  }
`;

const StyledButton = styled(Button)`
  ${tw`w-full md:w-max`}
  border-radius: 10px;
  height: 46px;
  margin-top: 27px;
  text-align: center;

  @media ${props => props.theme.screens.md} {
    height: 68px;
    font-size: 21px;
  }
`;

const DescriptionText = styled.p`
  font-family: Quicksand;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 31px;
  text-align: center;

  @media ${props => props.theme.screens.md} {
    font-size: 19px;
  }
`;

export const BookButton = ({buttonText}) => {
  return (
    <BookNowController>
      <StyledButton>{buttonText}</StyledButton>
    </BookNowController>
  );
};
export const DescriptionBlock = ({content, button_text}) => {
  return (
    <Container>
      <StyledBlock>
        <DescriptionText>{content}</DescriptionText>
        <div
          style={{
            display: 'flex',
            flexFlow: 'row wrap',
            justifyContent: 'center',
          }}
        >
          <BookButton buttonText={button_text} />
        </div>
      </StyledBlock>
    </Container>
  );
};

const work_with_us = {
  title2: 'cleaning_service_reasons_heading',
  items: [
    {
      title: 'cleaning_service_reasons_item_1_header',
      text: 'cleaning_service_reasons_item_1_content',
    },
    {
      title: 'cleaning_service_reasons_item_2_header',
      text: 'cleaning_service_reasons_item_2_content',
    },
    {
      title: 'cleaning_service_reasons_item_3_header',
      text: 'cleaning_service_reasons_item_3_content',
    },
    {
      title: 'cleaning_service_reasons_item_4_header',
      text: 'cleaning_service_reasons_item_4_content',
    },
    {
      title: 'cleaning_service_reasons_item_5_header',
      text: 'cleaning_service_reasons_item_5_content',
    },
  ],
};

const process = {
  title1: 'cleaning_service_process_heading',
  description: 'cleaning_service_process_description',
  items: [
    {
      title: 'cleaning_service_process_item_1_title',
      text: 'cleaning_service_process_item_1_text',
    },
    {
      title: 'cleaning_service_process_item_2_title',
      text: 'cleaning_service_process_item_2_text',
    },
    {
      title: 'cleaning_service_process_item_3_title',
      text: 'cleaning_service_process_item_3_text',
    },
    {
      title: 'cleaning_service_process_item_4_title',
      text: 'cleaning_service_process_item_4_text',
    },
  ],
};

const CleaningServicePage = ({data}) => {
  const {language, t} = useI18next();
  const {
    background,
    backgroundMobile,
    backgroundTablet,
    backgroundLocations,
    backgroundLocationsMobile,
    backgroundLocationsTablet,
    backgroundExpectatiton,
    backgroundExpectatitonTablet,
    faq,
    reviews,
  } = data;
  return (
    <Layout headerStyle="homeCleaning">
      <SEO
        title={t('seo_title')}
        description={t('seo_description')}
        lang={language}
        keywords={t('keywords', {returnObjects: true})}
      />
      <PageHero
        content={{
          heading: t(`cleaning_service_hero_heading`),
          text: t(`cleaning_service_hero_description`),
          button: t(`book_cleaning`),
        }}
        imgObj={{
          background,
          backgroundMobile: backgroundMobile,
          backgroundTablet: backgroundTablet,
        }}
      />
      <WorkWithUs
        content={work_with_us}
        bottomDescription={t(`cleaning_service_reasons_description`)}
      />
      <BookingProcess content={process} />
      <DescriptionBlock
        content={t(`cleaning_service_process_bottom_description`)}
        button_text={t(`book_cleaning`)}
      />
      <ImageItems
        heading={t(`cleaning_service_expanding_heading`)}
        description={t(`cleaning_service_expanding_description`)}
        image={backgroundLocations}
        mobileImage={backgroundLocationsMobile}
        tabletImage={backgroundLocationsTablet}
        subDescription={{
          content: `${t('cleaning_service_expanding_description_2')}`,
          button: t(`cleaning_service_expanding_button`),
        }}
        items={{
          generic: t(`cleaning_service_expanding_item_1`),
          item2: t(`cleaning_service_expanding_item_2`),
          item3: t(`cleaning_service_expanding_item_3`),
        }}
      />
      <ImageText
        background={backgroundExpectatiton}
        backgroundTablet={backgroundExpectatitonTablet}
        content={{
          heading: `${t('cleaning_service_expectation_heading')}`,
          description: `${t('cleaning_service_expectation_description')}`,
          items: `${t('cleaning_service_expectation_items')}`,
        }}
      />
      <div style={{marginBottom: 100}} />
      <DescriptionBlock
        content={t(`cleaning_service_expectation_subdescription`)}
        button_text={t(`book_cleaning`)}
      />
      <Reviews reviews={reviews.edges} headerStyle={{marginLeft: 0}} />
      <FAQ faqs={faq.edges} headingProps={{marginLeft: 0}} />
      <div style={{marginBottom: 100}} />
      <DescriptionBlock
        content={t(`cleaning_service_expectation_testemonial`)}
        button_text={t(`book_cleaning`)}
      />
      <div style={{marginBottom: 150}} />
    </Layout>
  );
};

export default CleaningServicePage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        language: {eq: $language}
        ns: {
          in: [
            "common"
            "cleaning_service"
            "seo_cleaning_services_prague"
            "homepage"
          ]
        }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    faq: allContentfulFaq(
      sort: {order: ASC, fields: createdAt}
      filter: {pageTag: {eq: "homepage"}, node_locale: {eq: $language}}
    ) {
      edges {
        node {
          childContentfulFaqAnswerTextNode {
            answer
          }
          question
          id
        }
      }
    }
    background: file(relativePath: {eq: "cleaning-service-hero.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          placeholder: NONE
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    backgroundTablet: file(
      relativePath: {eq: "cleaning-service-hero-tablet.png"}
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          placeholder: NONE
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    backgroundMobile: file(
      relativePath: {eq: "cleaning-service-hero-mobile.png"}
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          placeholder: NONE
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    backgroundLocations: file(
      relativePath: {eq: "cleaning-service-locations.png"}
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          placeholder: NONE
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    backgroundLocationsMobile: file(
      relativePath: {eq: "cleaning-service-locations-mobile.png"}
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          placeholder: NONE
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    backgroundLocationsTablet: file(
      relativePath: {eq: "cleaning-service-locations-tablet.png"}
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          placeholder: NONE
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    backgroundExpectatiton: file(
      relativePath: {eq: "cleaning-service-expectation.png"}
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          placeholder: NONE
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    backgroundExpectatitonTablet: file(
      relativePath: {eq: "cleaning-service-expectation-tablet.png"}
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          placeholder: NONE
          formats: [AUTO, WEBP, AVIF]
          quality: 90
        )
      }
    }
    reviews: allContentfulReviews(
      sort: {order: ASC, fields: createdAt}
      filter: {node_locale: {eq: $language}}
    ) {
      edges {
        node {
          comment {
            comment
          }
          rating
          title
          id
          name
          icon {
            gatsbyImageData(
              width: 800
              quality: 90
              layout: CONSTRAINED
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`;
